import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Profile, Balance } from './profile';
import { LookupEnums } from '@shared/enumeration/LookupEnum';
import { Utils } from '@shared/helper/utils';
import { SharedService } from '@core/services/shared.service';
import { StorageService } from '@core/services/storage.service';
import { MessagingService } from '@core/services/messaging.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  method = 'User';

  userAccounts;
  //balances:any[] = [];
  public balances;
  get apiUrl() {
    return environment.apiUrl;
  }
  public accountIdSubject = new BehaviorSubject(null)
  public profileData: Observable<Profile>;
  public profile = new BehaviorSubject(null)
  AppLaunch: boolean = false;
  get actionUrl() {
    return this.apiUrl + this.method;
  }
  constructor(
    private http: HttpClient,
    private _SharedService: SharedService,
    private _StorageService: StorageService,
    private _MessagingService: MessagingService
  ) { }
  _setAppLaunchData() {
    if (!this.AppLaunch) {
      this.AppLaunch = true;
      this._setProfileData();
      this._setUserAccounts();
    }
  }
  _updateProfileData(newProfileData) {
    this.profileData = newProfileData;
    this.profile.next(newProfileData)
    this._StorageService.setStorage('IsPersonal', (newProfileData.AccountTypeLKPId) ? newProfileData.AccountTypeLKPId : newProfileData.TypeLKPId);
    this._updateUserAccountsNames();
  }
  _logoutProfileData() {
    this.profileData = null;
    this._setAccountId(null);
    this.profile.next(null)
    this.userAccounts = null;
    this._MessagingService.unSubScribeAllTokens();
  }

  _setProfileData() {
    this._getProfile().then((x) => {
      if (x['Flag'] == 0) {
        this._updateProfileData(x['Result'])
        this._setAccountId(x['Result']['Id']);
        this.setverificationstatus(x['Result']['VerificationStatusLkpId'])
      }
      this.AppLaunch = false;
    })
  } 

  setverificationstatus(id){
    console.warn(id)
    localStorage.setItem('VerificationStatus', id);
  }

  _updateUserAccountsNames() {
    if (this.userAccounts && this.profileData) {
      let accountId = this.profileData['Id'];
      this.userAccounts.forEach((element, index) => {
        if (element.Id == accountId) {
          if (this.profileData['TypeLKPId'] == LookupEnums.UserTypeEnum.Business) {
            this.userAccounts[index].Name = this.profileData['Business'].CompanyName;
          }
          else this.userAccounts[index].Name = this.profileData['Customer'].FirstName + ' ' + this.profileData['Customer'].FamilyName;
        }
      });
    }
  }
  // _balances() {
  //   return this.balances
  // }
  public _balances(): Observable<Balance[]> {
    return this.balances;
  }
  set_balances(v = []) {
    let data = null;
    return this.http.post<any>(this.apiUrl + 'Transaction/BalanceList', data).subscribe(x => {
      if (x['Flag'] == 0) {
        this.balances = x['Result'];
        //console.warn(this.balances)
      }
    });
  }
  _setAccountId(id) {
    this.accountIdSubject.next(id)
  }
  _getAccountIdListen(): Observable<any> {
    return this.accountIdSubject.asObservable()
  }
  async _setUserAccounts() {
    await this._getUserAccounts().then(x => {
      if (x['Flag'] == 0) {
        this.userAccounts = x['Result'];
        this._updateUserAccountsNames();
      }
    })
  }
  public getProfileData(): Observable<Profile> {
    return this.profileData;
  }
  public getActiveAccount(): Observable<any> {
    let returnData: any = [];
    if (this.userAccounts) {
      this.userAccounts.forEach(element => {
        if (element['IsActive']) {
          returnData = element;
        }
      });

    }
    return returnData;
  }
  updateActiveAccount(user) {
    if (this.userAccounts) {
      let accountId = user.AccountId;
      this.userAccounts.forEach((element, index) => {
        if (!element['IsActive'] && element.Id == accountId) {
          this.userAccounts[index].IsActive = true;
        }
        else {
          this.userAccounts[index].IsActive = false;
        }
      });
    }
  }
  _updateProfileImage(image, accountId?) {
    if (!accountId) {
      let getLoggedInUser = this._SharedService.getCurrentAccount();
      accountId = getLoggedInUser;
    }
    let findIndex = this.userAccounts.findIndex(x => x.Id == accountId);
    if (findIndex >= 0) {
      this.userAccounts[findIndex].ProfileImage = null;
    }
    this.profileData['ProfileImage'] = null;
    //this._updateAgain(findIndex,image);
    setTimeout(() => {
      this._updateAgain(findIndex, image);
    }, 20)
  }
  _deletePhoto() {
    return this.http
      .delete<any>(this.actionUrl + '/DeleteProfileImage');
  }
  _updateAgain(findIndex, image) {
    this.userAccounts[findIndex].ProfileImage = image;
    this.profileData['ProfileImage'] = image;
  }
  public getAccounts(all: boolean = false): Observable<any[]> {
    let returnData: any = [];
    if (this.userAccounts) {
      if (all) {
        returnData = this.userAccounts;
      }
      else {
        this.userAccounts.forEach(element => {
          if (!element['IsActive']) {
            returnData.push(element);
          }
        });
      }
    }
    return returnData;
  }
  _addUserAccount(data) {
    return this.http
      .post<any>(this.apiUrl + 'Auth/AddUserAccount', data);
  }
  _activateDeActivateAccount(data) {
    return this.http
      .post<any>(this.apiUrl + 'Auth/ToggleAccountActivation', data);
  }
  _activateDeActivateUser(data) {
    return this.http
      .post<any>(this.apiUrl + 'Auth/ToggleUserActivation', data);
  }
  _deleteUserAccount(data) {
    return this.http
      .delete<any>(this.apiUrl + 'Auth/DeleteAccount', data);
  }
  deleteUserAccount(data) {
    return this.http
      .post<any>(this.apiUrl + 'Auth/DeleteAccount', data);
  }
  _deleteUser(data) {
    return this.http
      .post<any>(this.apiUrl + 'Auth/DeleteUser', data);
  }
  _getUserAccounts() {
    return this.http
      .get<any>(this.apiUrl + 'Auth/UserAccounts').toPromise();
  }
  _switchUserAccount(data) {
    return this.http
      .post<any>(this.apiUrl + 'Auth/SwitchAccount', data);
  }
  _SendEmailVerificationRequest(formdata) {
    return this.http.post(this.actionUrl + '/SendEmailVerificationRequest', formdata);
  }
  _getProfile() {
    return this.http.get(this.actionUrl + '/GetMyAccountInfo').toPromise();
  }
  _updateProfile(formdata) {
    return this.http.post(this.actionUrl + '/SavesAccountInfo', formdata);
  }
  // _SaveAddresses(formData) {
  //   return this.http.post(this.apiUrl + 'Address/SaveAddresses', formData);
  // }
  _uploadPhoto(fd) {
    return this.http
      .post<any>(this.actionUrl + '/UploadProfileImage', fd);
  }
  // _deleteAddress(id) {
  //   return this.http.delete(this.apiUrl + 'Address/DeleteAddress', { params: { id: id } })
  // }
  _getDocuments() {
    return this.http.get<any>(this.apiUrl + 'IdentificationDocument/GetIdentificationDocuments', { params: { withAttachment: true } })
  }

  _deleteItem(id) {
    return this.http.delete(this.apiUrl + 'Catalogue/DeleteItem', { params: { id: id } })
  }
  _deleteCatalogue(id) {
    return this.http.delete(this.apiUrl + 'Catalogue/DeleteCatalogue', { params: { id: id } })
  }
  GetMyItems(formData) {
    return this.http.post(this.apiUrl + 'Catalogue/GetMyItemsList', formData);
  }
  GetMyItem(id) {
    return this.http.get<any>(this.apiUrl + 'Catalogue/GetMyItemById', { params: { id: id } })
  }
  GetMyCatList(formData) {
    return this.http.post(this.apiUrl + 'Catalogue/GetMyCataloguesList', formData);
  }


  GetMyCat(id) {
    return this.http.get<any>(this.apiUrl + 'Catalogue/GetMyCatalogueById', { params: { id: id } })
  }
  AddCatalogueItem(formData) {
    return this.http.post<any>(this.apiUrl + 'Catalogue/AddUpdateItem', formData);

    // const httpObservable: Observable<any> = this.http.post<any>(this.apiUrl + 'Catalogue/AddUpdateItem', formData);
    // httpObservable.subscribe(
    //   (response: HttpResponse<any>) => {
    //     // The HTTP request was successful
    //     // console.log(response.body); // Process the response data
    //     return response.body;
    //   },
    //   (error: HttpErrorResponse) => {
    //     // An error occurred during the HTTP request
    //     console.log(error.message);
    //     console.error(error.message);
    //   }
    // );



  }
  AddCatalogue(formData) {
    return this.http.post<any>(this.apiUrl + 'Catalogue/AddUpdateCatalogue', formData)
  }
  getFinances(formData) {
    return this.http.post<any>(this.apiUrl + 'Transaction/MCollectTransactionsList', formData)
  }
  getMConnectFinances(formData) {
    return this.http.post<any>(this.apiUrl + 'Transaction/MCollectFinances', formData)
  }
  SetImageAsPrimary(id) {
    return this.http.post<any>(this.apiUrl + 'Catalogue/SetImageAsPrimary?id=' + id, [])
  }
  GetCategories() {
    return this.http.get<any>(this.apiUrl + 'Catalogue/GetAllCategories')
  }

  AcceptDeclineOwnership(postData) {
    return this.http.post<any>(this.apiUrl + 'Catalogue/AcceptDeclineOwnership ', postData)
  }
  GetOwnershipRequest(id) {
    return this.http.get<any>(this.apiUrl + 'Catalogue/GetOwnershipRequest?id=' + id)
  }
  CancelOwnership(id) {
    return this.http.post<any>(this.apiUrl + 'Catalogue/CancelOwnership?id=' + id, [])
  }
  OwnershipList(postData) {
    return this.http.post<any>(this.apiUrl + 'Catalogue/OwnershipRequestsList', postData)
  }

  VerifyEmail(Id) {
    return this.http.get<any>(this.apiUrl + 'User/VerifyEmail/' + Id)
  }
  _addDocument(fd) {
    return this.http.post<any>(this.apiUrl + 'IdentificationDocument/SaveIdentificationDocuments', fd);
  }
  _deleteDocument(fd) {
    return this.http
      .post<any>(this.apiUrl + 'IdentificationDocument/SaveIdentificationDocuments', fd);
  }

  checkProfileProgress(ProfileData) {
    let total = 0;
    let count = 0;
    let isBusiness = false;
    if (!Utils.isNullOrUndefinedOrEmpty(ProfileData)) {
      if (ProfileData['TypeLKPId'] == LookupEnums.UserTypeEnum.Business) {
        isBusiness = true;
      }
      if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Name)) {
        count++;
      }
      total++;
      /////
      if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Name)) {
        count++;
      }
      total++;
      /////
      if (isBusiness) {
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Business.CompanyName)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Business.SectorLKPId)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Business.CompanyTypeLKPId)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Business.Description)) {
          count++;
        }
        total++;
        ////////
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Address) || ProfileData.Address.length == 0) {
          count++;
        }
        total++;
        ////////
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.AccountEmail) || ProfileData.AccountEmail.length == 0) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.AccountContactNumber) || ProfileData.AccountContactNumber.length == 0) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.AccountWebsite) || ProfileData.AccountWebsite.length == 0) {
          count++;
        }
        total++;
      }
      else {
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.FirstName)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.FatherName)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.FamilyName)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.JobTitle)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.Bio)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.GenderLKPId)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.MaritalStatusLKPId)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.DateOfBirth)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.EducationLKPId)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.ProfessionLKPId)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.ProfessionOther)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.EmployerName)) {
          count++;
        }
        total++;
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Customer.Nationality)) {
          count++;
        }
        total++;
        ////////
        if (Utils.isNullOrUndefinedOrEmpty(ProfileData.Address) || ProfileData.Address.length == 0) {
          count++;
        }
        total++;
      }
      /////
      // if( Utils.isNullOrUndefinedOrEmpty(ProfileData.Address) || ProfileData.Address.length == 0 ) {
      //   count++;
      // }
      // total++;
      /////
      if (Utils.isNullOrUndefinedOrEmpty(ProfileData.AccountNumber) || ProfileData.AccountNumber.length == 0) {
        count++;
      }
      total++;
      /////
      if (Utils.isNullOrUndefinedOrEmpty(ProfileData.PaymentMethods) || ProfileData.PaymentMethods.length == 0) {
        count++;
      }
      total++;
      /////
      return 100 - (count / total * 100);
    }
    return 0;
  }
}
