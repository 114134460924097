export namespace LookupEnums {
	export enum LookupCategoryEnum {
		EducationLevel = 1,
		RegistrationType = 2,
		BusinessSectorType = 3,
		UserType = 4,
		Profession = 5,
		ContactUsTopic = 304,
		Currency = 7,
		CompanyNumber = 9,
		Countries = 10,
		SocialMedia = 11,
		MaritalStatus = 12,
		AgreementStatus = 13,
		AgreementType = 266,
		AgreementAccountType = 275,
		BusinessRoleType = 14,
		Gender = 15,
		InivteLinkType = 16,
		AccountType = 17,
		NotificationType = 18,
		ReminderType = 19,
		ReminderCategory = 362,
		Relationship = 20,
		CompanyType = 21,
		BusinessType = 22,
		ProfessionType = 23,
		AddressDetailType = 24,
		CancelFriendRequest = 25,
		Title = 26,
		IndividualIdentificationDocuments = 8,
		BusinessIdentificationDocuments = 27,
		OtherIdentificationDocumentsParent = 337,
		OtherIdentificationDocuments = 342,
		OccurrenceType = 28,
		PaymentMethod = 249,
		PaymentCategory = 267,
		PaymentStatuses = 280,
		InvoiceStatuses = 326,
		PaymentStatus = 280,
		AreebaOrderType = 804,
		InvoiceStatus = 326,
		TransactionStatus = 359,
		TransactionReason = 378,
		SecurityQuestions = 316,
		PaymentTermsInDays = 349,
		ContactPreferredMethod = 322,
		DeleteReason = 449,
		Constraints = 458,
		AccessControlFeature = 477,
		CatalogueStatus = 492,
		CatalogueType = 491,
		ItemOrderStatus = 495,
		WeekDays = 533,
		ReservationDaysType = 700,
		ReservationStatus = 541,
		BookingCategory = 110,
		Restaurants = 121,
		OwnershipStatus = 719,
		PlaceType = 764,
		SafetyItems = 759,
		PlaceOfferItems = 735,
		AmenitiesItems = 744,
		PlaceProperties = 765,
		SearchCategories = 821,
		AccountPointsStatus = 794,
		AccountPoints = 547,
		AccountStatus = 611,
		PenaltyOccurrence = 835,
		PenaltyType = 832,
		RoundingOptions = 843,
		LGB = 1000,
		Occupation = 946,
		MonthlyIncome = 947,
		OtherSourcesOfFunds = 948,
		VerificationStatus = 22
	}

	export enum EducationLevelEnum {
		Bachelor = "994a2465-9a80-4bf6-ba21-1ad719ad1fd8",
		Secondary = "065dc0ec-0c86-407d-9a7a-1bbee251f648",
		NoFormalEducation = "23a2adf9-b2a4-43e1-ba62-541e549a69f0",
		PHD = "5c122a0c-178a-47e5-844d-6175332807e0",
		Masters = "80e026f8-c6ee-4e17-b759-dd92faa5d6fe",
		Elementary = "12830442-6a1b-4560-8b41-fe3f4b670287",
	}
	export enum RegistrationTypeEnum {
		HoldingCompanies = "1e7c6cb1-5750-4345-856c-067594438ec3",
		OffshoreCompanies = "18c02b78-4d73-42bb-9c90-71c11bca12ec",
		CommercialRepresentation = "b2292571-e249-4b1a-86b4-8cd8444bde62",
		Partnership = "5d7d823e-43b2-4746-9c5c-97f0ef102c2c",
		ForeignEnterprise = "e596926c-b842-4602-9e43-9b628dd61212",
		SARL = "c9e928fe-58c0-4ecb-87c0-af7964c66508",
		SAL = "edf14931-35ce-4905-af82-c82f97fa7d2a",
	}
	export enum BusinessSectorTypeEnum {
		Other = "483a5a27-0e5c-4c5d-824e-00c29f3786f7",
		Education = "d1eda376-b94e-46c6-918e-043609df8386",
		Sports = "08acfea9-56a1-406a-a899-06be67f64e11",
		WebDevDesign = "cdf4749e-bed8-4f59-bc54-11faf370d775",
		DentalCare = "9e5f88e7-dd89-4c12-9c45-12784383ce61",
		PersonalServices = "3178f4a0-c8a9-439e-87ca-1e147350fb60",
		CleaningServices = "c0147d93-fc79-4f75-b5f4-1f05f21ce82b",
		MusicEntertainment = "52e590ac-c2af-433f-af78-23052a356736",
		Veterinary = "4063c88b-b15c-4f3c-b979-282255888240",
		TaxiLimo = "deed1d1a-bac0-47ee-a422-3061db060f1c",
		ProfessionalService = "700f30af-43c6-49ee-843f-3723b55c70df",
		Realestate = "cc2a2636-25b8-4cc8-a363-5f6c8a679912",
		Electronics = "34a3c07e-8bca-4fa7-bce7-6035d4acc693",
		MediaServices = "88045bf4-96e0-4470-bba1-605be1bc779e",
		Consultant = "945af3c6-d8e3-48df-af96-797bc18b7154",
		ComputerServices = "dde04012-2d0b-4770-8f5b-7e8fbd899f92",
		TradeContractor = "362f698b-3f11-4d63-99d8-81ebd61bfe68",
		Tourism = "2af59aa4-a2c1-4c5b-954d-845c07afb9f8",
		Health = "aa3acd2a-1298-4da6-8794-89689f1f9657",
		Landscaping = "01979978-e799-48f5-a80d-a3a913f26967",
		SoftwareDevelopmentDesign = "4726598d-0b46-4031-8120-aafd94e60c98",
		Accounting = "9fe0e839-f7ac-445a-b6df-b1dac6c24c71",
		Gift = "7a0833f8-6cf4-4c38-a948-b927589a3607",
		Accessories = "50ac5baa-8dae-4b59-b9c5-bac573aba9fb",
		FashionApparel = "e1ac4c05-aa2f-4987-a6b1-bdecc05ba936",
		BodyCare = "2b20e424-b302-453f-90c4-d0fb6dd4881c",
		OutdoorMarkets = "57bc98c9-25e5-4a5d-8d0c-d94389d0050c",
		ProfessionalServices = "587a76d9-989c-456f-b388-df2afcb549b8",
		Legalservices = "3ba1c2ab-9983-454f-b4b1-eb367383f02f",
		RepairServices = "d08d8dcf-4871-48ea-a1af-f005c27a0aaf",
		ArtPhotoFilm = "ac9c2936-dd42-4c57-b071-f1ca07f0f5a5",
		MembershipOrganization = "ffe48c5f-f387-430d-abe8-f308e50a2d68",
		Grocery = "4bad48c5-ab02-40a5-95cc-f475b54bdf0e",
		Beauty = "86d8c6e0-48ac-4189-b19c-f4a144bd6e60",
		FoodAndBeverage = "7e968eb9-e0a4-4e47-87b0-f83a16b2cf99",
		Recreation = "2b14c7c4-0176-4996-b168-fcaf3c606bd0",
	}
	export enum UserTypeEnum {
		Personal = 246,
		Business = 247,
	}
	export enum ProfessionEnum {
		AdministrationAndOfficeSupport = "8e9b4fba-354d-46e7-bda5-14c6c15e2f6c",
		HospitalityAndTourism = "b2531966-2cde-43c1-969a-1a61f2eb8b68",
		CommunityServicesAndDevelopment = "94663298-3e52-49c4-b8ac-1a6e9b461836",
		Construction = "a1887b81-9164-42a8-bacb-1b4b74c7e6b6",
		InformationAndCommunicationTechnology = "b0b2a2bf-631e-43a0-a28a-28074271616d",
		Farming_AnimalsAndConservation = "f6c75142-15a0-4fc0-9607-28c805430899",
		Manufacturing_TransportAndLogistics = "17c8da50-61f7-4da3-a4e7-36008da3850a",
		RealEstateAndProperty = "9c11c6cd-801a-4814-9afe-474abc9bc5fb",
		SelfEmployment = "4e644d55-f8e6-4161-a745-49c0bed73edd",
		Engineering = "96da8965-7111-4a70-9c0d-4d261d5d51a8",
		EducationAndTraining = "dfcf7242-9c4f-4e5d-9568-5ccc9c505412",
		HumanResourcesAndRecruitment = "dd929283-92a2-4696-940e-62bc14081044",
		TradesAndServices = "87d8d220-046e-4dcc-93d5-68f5c2924302",
		DesignAndArchitecture = "a64d7c5b-dd9e-45cd-973c-7341a0bf0cf7",
		CEOAndGeneralManagement = "8bb4c944-4b5b-4136-9f8f-755a66543dde",
		InsuranceAndSuperannuation = "34069817-6b7d-4232-a8d8-76dfc08549ae",
		ConsultingAndStrategy = "f605f4fc-3b82-416c-a1d4-8230e473ac07",
		SportAndRecreation = "662798ad-d0ae-4141-b613-8810151fd58f",
		RetailAndConsumerProducts = "9a7a2206-1f6f-43b0-8d2b-9f3cc73e6610",
		CallCenterAndCustomerServices = "680bad87-9fd9-46de-83da-aa4725ca74e4",
		MarketingAndCommunications = "b4e52e44-aae6-432c-8fb8-af9aa7aa1663",
		Accounting = "4db27223-9b1f-4865-99a1-b22b36a09ae9",
		Sales = "4b29f472-299d-4160-a6f7-d11447ad022a",
		Advertising_ArtsAndMedia = "835e77f9-4785-49b2-9d72-d1cd9aceadf7",
		ScienceAndTechnology = "e3f36fb0-9363-4aac-b3a5-d3cabb1a2440",
		Mining_ResourcesAndEnergy = "01de1912-3267-4315-adf2-d43ff968f207",
		Other = 116,
		GovernmentAndDefense = "fae58448-e952-4c0d-bd84-ea7c4bf3aa0c",
		Legal = "4f920c04-3d04-4307-94c4-fcb8b64212c2",
		HealthcareAndMedical = "96943713-ef06-4e53-8946-fddc9d992044",
	}
	export enum ContactUsTopicEnum {
		Other = "dc43c75d-223f-4392-9ef0-17fb0a478c8c",
		Sales = "35bbe842-d2b5-4c68-b787-1e385af1e760",
		Support = "196e7367-7c92-42c3-9017-812e691297a4",
	}
	export enum CurrencyEnum {
		LBP = "e9fd93e4-b896-4f44-b5f6-661fe9b1e3e4",
		USD = "16b19fc9-5fb4-452c-aa31-77163dab1bbd",
	}
	export enum IndividualIdentificationDocumentsEnum {
		// ResidencePermit = "8c786151-8aa7-4b71-b86e-05a3f36e40ef",
		NationalID = 124,
		// DrivingLicense = "f08934a3-162f-4c18-afb1-a6e0d6f2419d",
		Passport = 122
	}
	export enum CompanyNumberEnum {
		Imo = "7f72278a-6a2b-44b8-81e3-079816f2d738",
		Telegram = "ce20b82d-855f-4c25-ac11-6a4ad1a8fc81",
		WhatsApp = "9b0cef53-775c-4191-9579-ccd3fcbd8203",
	}
	export enum CountriesEnum {
		GD = "be1ea88e-38cb-48c6-a1fc-013a178f6592",
		FO = "8b211ffb-58b5-4eb1-8110-03d9c40e0add",
		WF = "9bf068c4-95a9-4d4f-b485-04ee7c9d33d6",
		FI = "8e5477dc-58d4-44db-a9f7-05c9e51c99b4",
		AD = "a924c07f-1b65-4578-98fd-05e4b3f27aaa",
		PR = "c8a30d7c-a1b7-41f3-9810-08e67fc65164",
		ID = "ce41afd3-d296-4d71-bcc7-0aaec1ef541f",
		KN = "343da628-773d-43a6-af34-0c887698863f",
		DZ = "ab2c2a4a-ec6a-4f94-abaa-0d5cbb03a0b2",
		AZ = "5d6101c1-6b86-476f-9e89-0dc2a480f731",
		TL = "cebf5f25-d05a-488d-b69c-0e8407ec86eb",
		NO = "cd3dfa4f-3b16-4a28-ba1d-0f16d83bd9c3",
		IT = "7e5fbc47-26d8-48c5-8b3f-142a7e302756",
		CU = "dcf1d369-5fc6-4b16-974e-1497668c9870",
		TM = "8163fff9-90ff-46e1-88d9-157850846e23",
		SY = "6cc5f754-14b7-409a-affb-176608f2da99",
		YT = "8b88eca2-4a96-4d8a-80f8-17de947470d9",
		SM = "b971e6ec-b64e-4c9d-87e8-18aa9659ced0",
		UZ = "f9ac13b3-857f-4355-ad49-1c75c9d02e5c",
		AG = "f85fe1ce-1179-4a50-a8ad-1d96b2ad062f",
		EC = "7e7e4261-1d21-4920-8b6f-1ed53a50d314",
		EH = "8ac9370f-7a38-4c6a-b621-1ed5f4704ee5",
		GH = "1efa3e7d-3101-4edc-9813-2002c24d978f",
		VN = "18ae6bce-e3cc-41f9-81c1-21b2873d418b",
		DO = "f4f7e2de-0a20-4d07-8cfa-21d4413a5228",
		PL = "5552ca11-3db4-4b06-a947-21e2ef51a3ca",
		BE = "0eb90708-56ac-49d7-becb-2309cf2e1aad",
		LV = "10ac0e2b-9dfc-4158-9981-2531c7d0ede6",
		BW = "80b290bb-62d1-40b0-af6c-286461672a2c",
		BZ = "bb6624b5-287f-47ca-a291-28b241908aca",
		WS = "f0f6775a-1841-41ae-bdd2-295b579e397c",
		PE = "888cc652-474f-4f56-bb79-2a97a825ec76",
		LC = "a8409ddc-cfd2-442f-b1f0-2a9e6073aac4",
		LK = "be7e8d29-1eee-4b1f-897f-2c4b9a47bea2",
		BH = "614d38d8-190e-4a40-8992-2d1400ddb4cc",
		KM = "036d784b-e3b4-4445-abd1-2d40d5e95edb",
		YE = "6115ff26-2bf2-4f3e-a9bb-3203b9d206be",
		CK = "acadad33-47f3-4f15-a8e4-349f38cdcc3a",
		AW = "a7c51cec-a5ec-4878-ba5b-35c68b3f737d",
		TK = "2119cfbd-6b13-4b60-b680-370384943ba3",
		BG = "9f6e36f5-0408-456a-a9b0-37210750e176",
		ZA = "c33c6775-71b1-4105-94ca-38ee00b0b9a7",
		BO = "d92dc8b8-8688-4f91-93bd-3a60d05b06c8",
		US = "b0902735-c4a7-49c8-a336-3b2e219df408",
		GW = "b61427cd-1a40-4bc1-9bb6-3b702e4cd7a6",
		NE = "6fb3385a-7f64-4ece-829a-3b9508e476ef",
		AX = "a37c062f-3a2f-4125-a1f0-3bcc4d47a72c",
		AF = "fdaff7a8-4793-46a4-895a-3bf16cd7ae5f",
		GE = "bfb14aee-2f47-4747-92e7-3d87f62f57c3",
		NP = "a2b2e0c3-50c7-48b1-8f7d-3e0d908882d4",
		NG = "b7cfe70c-7658-47e8-bd30-3e8f03efa31c",
		KP = "def88549-5be9-4ff9-86f5-3eef8f449d97",
		MC = "72081fd5-60dc-49fa-8659-41498163da88",
		SI = "794a5073-1c51-491e-8b6d-421ae68d6ab2",
		AM = "45587bc5-9a0e-4dcc-b516-437a15f40792",
		CX = "8ba190a2-c0b8-456d-9ab9-449534f65fdc",
		GR = "78cbfc8a-300b-453c-983a-44fe9f6de4d6",
		RO = "ab8ce701-b9a7-465d-985c-4558d677ffd2",
		TO = "2d99bbbf-2fb4-4367-aa79-474bfdbf9c76",
		BT = "d81d049b-3b5c-4a40-82ae-4802cbe3d032",
		NA = "d911d26e-2213-40f3-a28f-4899b2f9a552",
		MS = "47184fd9-5d38-4c53-90f9-48ef4238b68f",
		KI = "301b80a0-7474-48fc-886a-4a0b419ce8e4",
		SG = "f7029655-7417-4c3b-82b0-4ac63b19175c",
		HR = "3cbb5b2c-4078-44c2-9529-4c9771cf62c9",
		CM = "ac39b6f5-34cf-4975-a67a-4d2cced515e6",
		RS = "8f3ac937-cc05-472c-87cf-4f9fae09e1e2",
		LT = "4ef6978c-e627-435c-8e16-50f30473e0e5",
		ET = "cc889613-a4ae-45a6-9466-50f94d1ead0b",
		GF = "74bb5484-be14-4e61-b1e7-518c58041893",
		TJ = "4fb0ecae-af1e-4b7e-aac9-51af87993c42",
		MZ = "706b6001-19a0-48c5-9e19-52566e6179c2",
		GL = "2d17e8e2-33a1-4885-8c5a-5345781580a6",
		SC = "6ed73d75-fbcf-444c-945a-53cc159106cd",
		MN = "83f6b62b-c311-49ef-adf8-54138c49d632",
		CD = "e7f904db-7310-46f2-8af9-54dd8e90de9f",
		UG = "ac0ceff5-3344-4d1f-8e60-54e54a21506d",
		HT = "e3e37201-5aec-4a09-8abe-554a6745cbf8",
		PW = "8f85d6df-e9f6-4030-a6d8-573a8e74db2f",
		VI = "7a7b008f-b555-4ab3-90b6-57590434e0d3",
		BB = "b4f4424a-b862-43cf-8fc2-57f334c0f3c3",
		MH = "46a0d955-bdc9-4f43-b223-584b1c00fa13",
		ES = "453ab0d8-2c8f-420a-b1bc-5a279f5a668c",
		LA = "120b3946-3ef0-401a-8085-5c4499c4abc3",
		GY = "8024ef8f-7b6c-4455-bd93-5c647ab6cc03",
		TC = "fde7ff00-2d31-44ff-9303-5cd18ed2e3af",
		DM = "a1de1f44-ec5f-4494-a84c-5d7a8d971034",
		JE = "7dd15851-d25a-45da-a744-5e888c0fa12d",
		IN = "fc45a0a7-5314-46db-a4fa-5ed228426545",
		BM = "23e4b1a0-1fdf-4d7c-be62-5fa07c5edf8d",
		SZ = "d7a20bc6-4070-4953-aa86-609c85abb4e3",
		KG = "827c7eca-0705-4672-8166-60f65d60c812",
		LU = "575279e5-864f-4b77-a050-61069c7e70ef",
		TR = "2d50f08c-c5b0-4acf-9f69-61d4a0010e2a",
		SL = "2aa8b1b4-c9ae-4f6d-bd11-62a904855521",
		JP = "21b1679e-00ba-4f81-8e29-630758589b1f",
		FR = "fad7ce59-13f1-44f1-a9e8-6452590fab0b",
		IQ = "6bea336b-47d8-470e-aada-6455f752b0af",
		PS = "8734c1a3-68f0-4fad-9da5-666bdb86f646",
		MT = "7775326b-16d2-4059-b6f6-6816bce54685",
		CH = "a86045bf-9d72-462c-8e14-68d77ad8d591",
		BJ = "3447d291-39d1-4073-a6a1-6a129eed9fd4",
		MW = "58ca3097-c097-4a49-be15-6a84cc8b3fa6",
		MY = "aa607e84-a96c-4423-9841-6a9b47c84bb8",
		FK = "adef769a-3c71-4f0c-83de-6aa7884b02fa",
		CZ = "9b5e94c5-58af-45c9-87ee-6d30e7635020",
		MU = "df9c70c2-43ce-47c1-8306-6d8f8b80f30e",
		GP = "4afe0931-0c76-478c-9c1d-6e0f26382546",
		PK = "60629c9d-54e8-4355-9095-7156c8d25a8e",
		PG = "861eda08-a622-4e3d-80a6-734c0ef1818c",
		CA = "12094455-0d56-40db-8d02-73b214215a89",
		CR = "37b56e1c-68ba-4bf6-95d6-747d8d64d1d0",
		SB = "631c9568-9268-47b9-baa1-74d9e7cb4349",
		DJ = "2f76e9d7-7358-4a34-b95b-7502b395720f",
		BF = "f43e70f3-6124-4158-a113-78b340cb9c39",
		MA = "2117d797-ef53-4bca-98df-7b523c24005b",
		ST = "7dcc3c0f-7180-4e62-a4c6-7b5de22c0aba",
		TV = "3d6004f5-ce69-49b3-9c5f-7cfcc006a395",
		JO = "2f72e1a8-2177-4d17-ac59-7ef16a2e273b",
		GB = "db160a8e-3fa5-494c-bb02-80bb9bee2cc0",
		AR = "86634907-07c1-41a5-8b01-8195f2b09d5d",
		BN = "aa1233e3-a069-41cd-b842-85549e983759",
		BR = "ed300718-d021-4c50-bbdd-8796d90c2d60",
		FJ = "4804763d-30f2-4792-90de-88bdf0ae2e4b",
		LY = "c8ea10f0-219a-4e51-a5a3-8b5a1525aef2",
		KE = "f3f8e1ff-8351-4091-8048-8ba18b2c4308",
		AO = "8788f35e-1cb8-496c-a6f4-8c3e7c4b8ab3",
		SJ = "f0407132-8844-44f5-8af4-8c953e1216a6",
		HU = "3499521d-8eb4-4db0-a56d-8c9ce48acdb4",
		SH = "d92deed5-e1b5-4480-be35-8ee094b96c07",
		MV = "94f1bfb7-8c32-4baf-9582-906e6843a0c6",
		FM = "4b3cb9e6-1d68-44f7-8107-916574fdae65",
		ME = "8b00b85f-d6f2-442d-a48e-925896affeb0",
		MR = "b752c696-1e73-475d-b884-964e7c338bd4",
		KZ = "34348154-1f17-4cfa-bd27-966900496966",
		CF = "1c17c894-ece7-49c0-b06d-99b08072cac2",
		AL = "35b8393e-4477-49ae-b764-9a56fddb485c",
		LI = "a3be0913-5a74-47be-941b-9b9cba0ad8e0",
		CN = "5ea09051-688b-4278-a440-9bc8ca8cbe5e",
		NC = "a2804120-99d4-4dca-8e21-9d569ace5895",
		CL = "aa7156c6-0253-4a55-9619-9e427f89770f",
		IE = "f8ef1e95-4cac-4332-9076-9e69b36624bb",
		SN = "d4aaae94-7a87-41d4-aec2-9eb02f7f3483",
		PA = "2586dc62-a6ed-47ef-b0dd-9ebd2cd9a41f",
		MP = "c435ab26-73a9-4633-afd7-9eca596289a7",
		KW = "4c0c2e61-4c72-427e-8d24-9f22abed9d9c",
		EE = "d7fbe6f3-d43f-47e7-a386-9f8e8fe04b11",
		NZ = "915b6960-f0fa-4d8e-afb7-a0bb56fb7892",
		MG = "acef9b9b-334a-419a-bae5-a414acca8921",
		KH = "190cb928-71d3-4570-8d21-a655925e6459",
		LS = "518776d6-4345-45a9-97b3-a78daf7f0946",
		SD = "4a2df62e-f376-4754-9c92-a7a59169024a",
		NL = "e5f4bd10-0aa6-4fe7-966a-a7cd29cd50f3",
		TH = "cabe3871-e943-4a4c-a401-a87992b3a536",
		VC = "35ea49ca-6c25-472d-baf8-a8c828eda399",
		HN = "d277f470-9940-4ad8-9d05-a98f76bd7b61",
		VG = "3d752a44-db3b-487e-a43d-a9fe236b9e3e",
		BS = "9f151c72-9da6-46f4-8cea-acfbe2ff9fef",
		TT = "29e7252b-73d0-42fd-8aaa-aede06226a18",
		GI = "398b6824-3689-41db-abaf-af3e86e8a9d6",
		CV = "30f32c4e-ed37-49b2-aa04-b03e824571be",
		AN = "57bc6057-7a76-43db-9800-b069cf1b7f77",
		QA = "1dc2b9be-1384-4256-bef1-b0fb902bf3a0",
		RU = "0642788b-5e8c-45d0-bceb-b1c683674bd2",
		JM = "edab2fff-c621-4789-b8ff-b24355ef7630",
		BD = "a16eae05-a090-42a3-abe1-b3a977320189",
		IO = "844efcd9-ff6b-4396-aeff-b419bd7c3222",
		LB = "59e7fa72-fc2e-4339-951d-b53b57b98564",
		UA = "eef11f40-e62d-4a4a-8813-b8ee572211b4",
		DK = "3f06c283-4314-45b6-84c1-bbf0863138a4",
		AI = "f5033f5e-751a-40dd-9c38-bd822670aacd",
		PY = "f18af98e-8a8b-4281-a6b4-bdb88132aa06",
		VU = "7e7e5177-5317-47e2-ab81-bdc1cf4cd273",
		SE = "ad609169-cdd8-4df4-b5ca-be61b35bdab3",
		ML = "144ba439-d244-4f9a-8950-c1b9fcc478c1",
		SV = "8ca94c4b-ce53-4418-891d-c21cd61db4dc",
		IS = "9d347c59-1f44-4278-83dd-c32469ef8993",
		GT = "1c6cd758-9c81-43a2-8a8b-c36b95480fac",
		RW = "0984ffc1-6dff-41c9-9722-c4538d0e7b7f",
		ZW = "6cacf747-d0e7-40df-9610-c4f41428b2ae",
		NF = "5b91c529-22e9-4696-88ad-c6197067d6f9",
		NI = "57b6c178-885c-4a9a-a863-c69bf2d9d214",
		MX = "6230da38-8762-4ea1-806e-c872eb661ca2",
		CI = "7d73a9c9-a936-472f-9404-c8a086d2cc15",
		TG = "07f6f13f-04fc-4840-9754-c8a7c778b499",
		RE = "212570da-ad13-4099-bff9-c8b88c41284c",
		CO = "ae89ff95-1f54-4cac-bb68-c9b7960188a7",
		BY = "db3176ec-0bb5-438b-817f-cab0d5d8468e",
		NR = "5fcf1451-c323-4d77-b794-cb6e62e05940",
		ZM = "2312e493-3cab-4390-8258-cbf4dfb35772",
		SA = "22ee143d-0c53-4c30-b6f2-cc71d48f4e69",
		UY = "937c05c0-0a9a-4b99-8ac5-cd802f1d5fac",
		MM = "f69d852b-f678-4797-9aa4-cf96a5ab7b6e",
		CC = "d56803e0-e456-4709-89c0-cfdf8f47696c",
		ER = "3191bb4b-1a73-4135-bb82-d03aa2d5f951",
		KY = "70ea7dd8-ae5c-44e2-a479-d0a1d66eff79",
		PH = "1992c781-51d7-4975-a80d-d30fd843f1ee",
		MO = "75109141-0ea9-427b-b9d7-d3136f7fe9b7",
		CG = "fbe2606d-d935-48ef-81d2-d3511661754a",
		OM = "7cd63697-0d2c-4267-9b80-d384583e01a4",
		GM = "099a32fc-aec6-42b4-85d4-d386ce119961",
		AE = "2e921b27-7ab7-4805-8184-d41925badf7b",
		HK = "e25f05b5-340b-4394-a511-d42d54cb19a8",
		TW = "d34bc62e-feb0-4fce-9183-d49051d34626",
		IM = "35f8299e-cf8c-45f8-b650-d49c7d1c36f0",
		MQ = "2b040f3e-b106-4be9-abe5-d61788f9ad61",
		CS = "086d3643-4cdb-4664-92b0-d693d96cc249",
		VA = "c860c486-7289-4052-ac25-d84911d4ec05",
		GN = "a10afc5a-0b5c-49f3-8879-d8ce905d25ee",
		CY = "fd970e4c-3038-4d3b-bdbc-d95fe2d83d0c",
		BA = "18c4056a-4c58-4b2c-8b89-d96fe7ef9750",
		TZ = "bad0dddd-f001-4312-8315-df42dc69c637",
		TD = "e0226a13-a59b-4474-9266-dfaed461c706",
		SR = "866924f9-1523-4956-9a6a-e023f4feb660",
		VE = "5f9f2b7f-9110-460f-ac2c-e695f88e20ff",
		EG = "5faeb3c6-4544-4ac4-9d60-e82dead8dfba",
		NU = "f2e637e6-db65-4272-b7a3-e841ac37b043",
		AU = "1b94d277-8cf1-4031-a128-e875243f8bb4",
		AT = "c246da70-0ec1-41ca-8eb9-ea5579c0f9e2",
		SO = "4ffa553e-53f1-40ff-a684-eb5345fcd161",
		MK = "adb5f3c6-dc38-4d25-9c2f-ed267b918055",
		TN = "5e77a025-f1a0-4fe8-9526-edbe1789d219",
		LR = "d0e1d3f7-168b-4d4b-a63c-eed2fc1cee88",
		GQ = "9b87c2d7-b5aa-4244-b635-ef6930e20735",
		DE = "77483519-7bf8-44c3-b5af-f02fcb533cb5",
		BI = "9cf478e6-50db-404b-b552-f575912af2b8",
		PM = "f86f11d2-e090-42b0-9acc-f5d990ac18b4",
		AS = "66758b1f-9e8d-49bb-aab9-f624402a33cf",
		MD = "3dc5199d-5aa3-4bb1-8be4-f64bdef2648a",
		GU = "80108430-e0e2-477f-a55e-f79636710688",
		PF = "3eee79c2-297a-4de6-b562-f79926f583dc",
		IR = "3e8d729b-0c8a-4dff-9f58-f7a06ce65499",
		PT = "b5253f86-727d-49d1-8602-fa5496483310",
		GA = "7e681a1e-53a4-402e-a9bc-fb2cc4305853",
		SK = "abbcf574-7b40-4a3f-8fd8-fc97c2b56d34",
		KR = "fc0006f3-436a-4b0d-bd14-fe2c7a068bea",
		IL = "366c657c-0fcc-4d1c-aa88-ff11a316504f",
	}

	export enum AgreementType {
		Contract = 268,
		Subscription = 272,
		SubscriptionPayAsYouGo = 269,
		CreditLine = 273
	}

	export enum AgreementCreateType {
		Draft = 292,
		Template = 291
	}

	export enum SocialMediaEnum {
		LinkedIn = 129,
		Snapchat = 133,
		Instagram = 131,
		Facebook = 130,
		Twitter = 132,
		YouTube = 134
	}
	export enum MaritalStatusEnum {
		Widowed = "f56cb337-3cd0-4c7a-b14e-0f35cd0b6fd0",
		Single = "d7a90653-e857-4052-a85c-5656781ad852",
		Divorced = "27ad312a-9c1c-461c-bc25-7200a650972b",
		Married = "7186c7cd-b752-46a0-a358-f5a92cd73730",
	}
	export enum AgreementStatusEnum {
		SendAgreement = "610d5366-ae44-43a3-bf1f-3b8e6fe4d96b",
		AgreementDeclined = "16182adc-4650-4d6d-bfd2-40bc84f69b3a",
		AgreementEditDeclined = "872f1986-20e2-487c-ad59-78453305fd5f",
		AgreementAccepted = "bc716039-7daf-4af0-8d9b-a5674505362f",
		EditAcceptedAgreement = "dd5242a5-8cee-44a9-92d2-dfa0b00c45eb",
		EditAgreement = "f6544207-a55b-4b6d-b986-f42a8dd2cda1",
		// new
		Active = 287,
		Amended = 288,
		Pending = 289,
		Completed = 290,
		Template = 291,
		Draft = 292,
		Declined = 308,
		Cancelled = 354,
		UnderReview = 356,
		Frozen = 822,
		Paused = 618
	}
	export enum BusinessRoleTypeEnum {
		Owner = "8bfce0e4-b845-4361-8591-4a62aaedf013",
		Editor = "35b897d1-4b2c-4e0b-b01d-84ecb2429496",
		Admin = "46338eb3-e299-4b02-bd4f-f71e705e0703",
	}
	export enum GenderEnum {
		Female = "8e854e23-0d61-4278-bf35-4631dfc98dc5",
		Male = "787bfb5a-c743-466e-9799-de4155520ed0",
	}
	export enum InivteLinkTypeEnum {
		InviteExternalLink = "f1f9aac3-38e9-469d-ada0-b8627b7f3dce",
		ChangeOwner = "89e932a3-975f-4cdd-acee-f221c9b263c0",
	}
	export enum AccountTypeEnum {
		Business = "c03655be-eb08-4cb9-98d2-3fb6d0602dc9",
		Customer = "8af5628e-d9b8-4007-8eba-9e15fba08724",
	}
	export enum NotificationTypeEnum {
		AgreementDeclined = "658f5cfc-0bb9-4882-a0f0-0011ac6896af",
		SendLendRequest = "a7ebd7a3-88a5-40bb-9b7a-17d4a61e97b4",
		ReceiveInboxMessage = "048bc5b9-cc5c-4c19-955f-2a52850ffa36",
		SendEditedAgreementAccepted = "d0cb852b-b054-408d-964e-43dd37637e1a",
		ClosedAgreement = "dc7fb3dd-14a7-475a-8073-54eb41964d4c",
		PendingPaymentAfterFewDays = "1c5fa185-8353-4642-a3cb-5ab7bc3dafe0",
		AutoClosedAgreement = "132344ba-b120-4a1f-b0cf-5ea77dbc7110",
		MakePayment = "abb1efd5-d8a4-4059-8e29-6688bce1d321",
		SendNewFriendRequest = "19e4ed95-3dd9-4fc4-850f-6e03a4861be1",
		PendingPaymentToday = "75e5f412-f34f-41d3-948e-7d903adbc0e0",
		BorrowRequestDeclined = "fa0eadb6-3374-447e-b51a-7efa2fefb5bd",
		MakePaymentDeclined = "01bb165b-f765-4a5c-8a37-7fdc8b2285ab",
		SendNewAgreement = "be604fa3-f07b-4cb8-a2a8-82c68450a204",
		LendRequestDeclined = "2900b525-216d-43bf-962a-8635857378b4",
		MakePaymentAccepted = "8e606c0c-95bd-4d48-8193-8aa34b7ca94e",
		AgreementAccepted = "3fb4bb60-5147-468d-a399-96348394fd74",
		CancelFriendRequest = "fc17c945-913a-44ee-9cfc-98586f8cd48e",
		NewFriendRequestAccepted = "3ea40978-f3c2-402d-8b5b-995d19ef74d3",
		SendPaymentReceipt = "75cb1176-38ed-414e-9701-99a0cd6ff369",
		BorrowRequestAccepted = "d77de5b8-2bdd-46a8-a9dd-9a11e0dd9cd3",
		PendingPaymentOverDued = "44df412e-8d0b-4993-b1a7-b74e3979d5df",
		SendEditedAgreement = "6a2490e5-adb9-450f-b2cf-da890f7aa870",
		LendRequestAccepted = "09507963-4def-41f3-868a-e16fa9023dbf",
		RequestExtension = "648b906f-fa6c-46c3-8e29-e35b6ac8a152",
		NewFriendRequestDeclined = "e0296a09-377b-4924-90a2-eb3824d45640",
		ReceivePayment = "a980fd4c-a8de-4dad-82d1-ebc1294bd86a",
		SendBorrowRequest = "5eb33295-76ca-4737-88ca-f8bec19a96dc",
		SendEditedAgreementDeclined = "ec98c368-6093-4674-88f0-fb71206aea1f",
	}
	export enum ReminderTypeEnum {
		EditAgreement = "c9282884-2417-4ee5-820f-08a9cd9b314b",
		MakePayment = "b41661e6-2239-4c95-b288-0a2b4603d4a5",
		AgreementDeclined = "dc4e7e11-dabf-4074-9954-0d2f0e5d26c3",
		AgreementAccepted = "92e2d717-e145-45b7-96fa-15c599ccd7be",
		LendMoney = "a169dd05-18d7-4a47-b783-3b8d602d6629",
		LinkBusiness = "3798c932-c2f2-4b0e-9861-3c668f84ad8d",
		AcceptLinkBusiness = "abc93ca0-6555-4ae3-ac24-4fef254d187e",
		BorrowMoney = "5eb3dccb-132d-4372-b103-530c8886462f",
		NewFriendRequestAccepted = "94d08693-9dd7-4f1e-a96a-6a8c7320cd8b",
		SendPaymentReceipt = "a321be33-8870-4f62-be5e-6ee68bc8f028",
		DeclineMakePayment = "7d06246c-8bd9-4d7c-a67d-7116bc8173f8",
		ChangeOwner = "99292e9f-5dad-4cbb-9a90-a3b0389681f4",
		AcceptMakePayment = "b99f9e0b-5a7f-40d5-8ab5-a6e65fbaf07d",
		AcceptChangeOwner = "f6639741-1816-4e59-9712-c141ea6dd2b3",
		ReceiveInboxMessage = "0b71910e-e82b-4fea-9802-c54257eb3d2c",
		AcceptBorrowRequest = "938e9c99-804b-4970-98d7-c5f7ff9d2bf7",
		NewFriendRequestDeclined = "7d40a4bc-4d81-48b9-ac00-d1082b7d24d9",
		DeclineChangeOwner = "56295dba-a7e3-494f-b439-d28dd5ca27e8",
		DeclineLendRequest = "1fbbad00-69fc-41c1-b8ea-da45c02c8cd0",
		AcceptLendRequest = "348f7be4-d3d9-48cc-8d4d-de78a0d9c7a5",
		NewFriendRequest = "5468d0b1-7d50-47a9-892d-df2f5c583acb",
		SendAgreement = "e2acb01b-e43c-4e27-8341-f4470a67e4c4",
		ReceivePayment = "05d0f1fb-8c83-47f4-9f22-f5fab46e936e",
		DeclineBorrowRequest = "7524fb54-1df8-4c82-b6b9-f984c88176d4",
	}
	export enum RelationshipEnum {
		Son = "daaa479e-8a40-42a9-8bf3-036588a91d67",
		Daughter = "c0ef0749-3d58-4430-b373-050524f1384d",
		Brother = "aebfd46a-0d38-4125-a429-2b0d999cc075",
		Sister = "845bda0e-a953-49f3-8fc1-4452156f6de3",
		Father = "26cee346-ef93-4ff7-b7ad-47f2216af91d",
		Spouse = "5ac3ecdc-88a5-4488-941e-70c39a2751ff",
		Friend = "740f88fe-a2e5-4576-95d8-866bcfc850cb",
		Other = "7d237acf-9807-4e06-8862-be3bb522e246",
		Mother = "c65d77ea-8560-4405-a776-f82cf2c5e49d",
	}
	export enum CompanyTypeEnum {
		ForeignEnterprise = "b572e17d-18a2-446d-b68b-032d613aa2cf",
		OffshoreCompanies = "ecfe8557-d89c-4018-924c-0e08eff00a78",
		CommercialRepresentation = "c2a2d124-0d01-44b1-95c4-6c92306064f9",
		JointStockCompany = "b34f94bf-5254-478e-9763-7fd89a9e7001",
		HoldingCompanies = "6055cdc6-d6c6-47b1-b8b3-800b8cbb3762",
		Partnership = "a2789541-e312-441f-8b5c-84ff2e3ba013",
		LimitedLiabilityCompany = "7b59f2cb-2509-4a3d-b201-d06dc43df80b",
	}
	export enum BusinessTypeEnum {
		PrivateCompany = "292065d6-8f99-497b-8f9d-30240c75c863",
		Freelance = "286e3d28-7244-47d3-82ac-3fbbbab66e14",
		Charity = "75d88a22-a0c9-4870-a7f3-4ecafccd0f18",
		PublicSector = "b6ce5509-4c30-4bfe-b68d-b917e0ce1e0b",
		Others = "90c87484-adfa-4cb0-8cfa-db3db1b1c754",
	}
	export enum ProfessionTypeEnum {
		Personal = "80ac5ece-bfb5-4943-850a-0cbba9fe411f",
		Business = "f45c6556-abfc-46dd-91ab-9ecad33feb70"
	}
	export enum AddressDetailTypeEnum {
		Email = "8591cfc3-116e-42f4-886f-f0b7dd78f09f",
		Phone = "ed356d24-f405-47dc-aa0e-f43116f81b59",
	}
	export enum CancelFriendRequestEnum {
	}
	export enum TitleEnum {
		Ms = "4958b7e5-8af7-43bc-98e7-212321f92da2",
		Mrs = "bcb36d94-9dac-4038-aa59-39a55b0f688c",
		Dr = "faeaa7f7-eb86-4ffe-acf8-4f6bdbf76a93",
		Other = "ccdbe06d-e8ae-4939-a171-87a9db0eca2f",
		Miss = "9c731459-1d3b-4dd1-ad38-942d195564cd",
		Mr = "b4bf3e8d-54b5-4fac-907e-d1ef684cc4fa",
	}
	export enum BusinessIdentificationDocumentsEnum {
		// CommercialCircular = "edccccaa-f6f2-4853-a70c-063e9dbfb20e",
		// CompanyRegistration = "29736048-caf0-4cee-889a-10667bcef7c6",
		CommercialCircular = 238,
		CompanyRegistration = 239,
	}
	export enum OccurrenceTypeEnum {
		Other = 348,
		HalfYearly = 245,
		Yearly = 244,
		Weekly = 243,
		Fortnightly = 242,
		Quarterly = 241,
		Monthly = 240,
	}
	export enum PaymentMethods {
		Cash = 254,
		Bank = 252,
		LGB = 255,
		WhishMoney = 250,
		Matensa = 270
	}



	export enum PaymentMethodsByCode {
		Cash = 254,
		Bank = 252,
		//Account = 249,
		LGB = 255,
		WhishMoney = 250,
		Matensa = 270
	}

	export enum PaymentStatuses {
		Paid = 286,
		OverDue = 285,
		OnHold = 445,
		Due = 353,
		Pending = 284,
		Cancelled = 463,
		Created = 283,
		Paused = 615

	}
	export enum InvoiceStatuses {
		Paid = 329,
		Active = 328,
		OverDue = 327,
		Due = 355,
		Cancelled = 375,
		OnHold = 18,
		Paused = 616
	}
	export enum TransactionStatuses {
		Pending = 360,
		Paused = 617
	}
	export enum TransactionReason {
		CashOut = 382,
	}
	export enum AppConfig {
		TotalReceiversDisplay = 3
	}
	export enum FailerReason {
		AddCustomer = 2,
		AddAmount = 6,
		AddContact = 10
	}
	export enum Constraints {
		DocumentMaxSize = 459
	}
	export enum AccessControlFeature {
		GiftCards = 474,
		MarketPlace = 17,
		MobileRecharge = 480,
		ExchangeRate = 481,
		WithdrawMoney = 482,
		TransferMoney = 483,
		CreateOffer = 484,
		CreateInvoice = 485,
		Report = 513,
		Catalogue = 532,
		TopUp = 486,
		Reservations = 841,
		Restaurants = 842,
		Payroll = 958
	}
	export enum CatalogueStatus {
		Draft = 503,
		InStock = 504,
		OutOfStock = 505,
		PreOrder = 506,
	}
	export enum CatalogueType {
		Physical = 497,
		Service = 498,
		Custom = 502,
		Reservation = 499,
		Digital = 500,
		Bundle = 501,
	}
	export enum ItemOrderStatus {
		Pending = 512,
		Processing = 514,
		Completed = 515,
		Cancelled = 516,
		Refunded = 517,
		Draft = 511,
		Expired = 521,
		Rejected = 525

	}
	export enum WeekDays {
		Sat = 534,
		Sun = 535,
		Mon = 536,
		Tus = 537,
		Wed = 538,
		Thu = 539,
		Fri = 540
	}
	export enum ReservationDaysType {
		SpecialDay = 702,
		Holiday = 701,
	}
	export enum ReservationStatus {
		Published = 543,
		Draft = 542,
	}
	export enum OwnershipStatus {
		Accepted = 720,
		Declined = 721,
		Pending = 722,
		CancelledBySupplier = 723,
		CancelledByOwner = 724

	}
	export enum PlaceType {
		ARoom = 766,
		AnEntirePlace = 765,
		ASharedRoom = 767,

	}
	export enum SafetyItems {
		SmokeAlarm = 760,
		FirstAidKit = 761,
		FireExtinguisher = 762,
		CarbonMonoxideAlarm = 763
	}
	export enum PlaceOfferItems {
		Wifi = 736,
		TV = 737,
		Kitchen = 738,
		WashingMachine = 739,
		FreeParking = 740,
		PaidParking = 741,
		AirConditioning = 742,
		DedicatedWorkspace = 743
	}
	export enum AmenitiesItems {
		Pool = 745,
		HotTub = 746,
		Patio = 747,
		BBQGrill = 748,
		OutdoorDiningArea = 749,
		FirePit = 750,
		PoolTable = 751,
		IndoorFireplace = 752,
		Piano = 753,
		ExerciseEquipment = 754,
		LakeAccess = 755,
		BeachAccess = 756,
		SkiIn_SkiOut = 757,
		OutdoorShower = 758
	}

	export enum PlaceProperties {
		Guests = 769,
		Bedrooms = 770,
		Bed = 771,
		Bathrooms = 772,

	}
	export enum AccountStatus {
		Active = 612,
		Blocked = 613,
		Frozen = 614,
	}
	export enum SearchCategories {
		Invoices = 815,
		Partnerships = 816,
		MatensaAccounts = 817,
		Catalogues = 818,
		Reservation = 819,
		Tickets = 820

	}
	export enum PenaltyType {
		Incremental = 833,
		Cumulative = 834
	}
	export enum PenaltyOccurrence {
		Weekly = 836,
		Monthly = 837,
		Quarterly = 838,
		Annual = 839
	}
	export enum RoundingOptions {
		One = 844,
		Ten = 845,
		Hundred = 846,
		Thousand = 847,
		TenThousand = 848
	}
	export enum LGB {
		ATM = '255-ATM',
		Counter = '255-Counter',
		MobileApp = '255-Mobile',
	}
	export enum VerificationStatus {
		Verified = 23,
		NotVerified = 24,
		OnHold = 25,
		Declined = 26,
		Pending = 36
	}
}
