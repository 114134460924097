import { HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { StorageService } from '@core/services/storage.service';
import { AuthService } from '@modules/auth/auth.service';
import { SharedService } from '@core/services/shared.service';

const TOKEN_REFRESH_KEY = 'refreshToken';
const TOKEN_HEADER_KEY = 'Authorization';  // for Spring Boot back-end
//const TOKEN_HEADER_KEY = 'x-access-token';    // for Node.js Express back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private requests: string[] = [];
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private tokenService: StorageService, 
    private authService: AuthService,
    private sharedService: SharedService,
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.tokenService.getStorage('token');
    const refreshToken = this.tokenService.getStorage('refreshToken');
    if (token != null) {
      authReq = this.addTokenHeader(req, {AuthToken:token,refreshToken:refreshToken});
    }
    return next.handle(authReq).pipe(
      catchError(error => {
        this.sharedService.removeRequest(authReq);
        if (error instanceof HttpErrorResponse && !authReq.url.includes('Auth/Login') && error.status === 401) {
          if(authReq.url.includes('Auth/RefreshToken')) {
            this.isRefreshing = false;         
            this.authService.logout_noauth();
          }
          else {
            return this.handle401Error(authReq, next);
          }
        }
        return throwError(error);
      })
    );
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
     // const token = this.tokenService.getToken('token');
      let token = this.tokenService.getStorage('token');
      if (token) {
        return this.authService.refreshToken().pipe(
          catchError((err) => {
            this.isRefreshing = false;         
            this.authService.logout_noauth();
            return throwError(err);
          }),
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.tokenService.setStorage('token',token['Result']['AuthToken']);
            this.tokenService.saveRefreshToken(token['Result']['refreshToken']);
            this.refreshTokenSubject.next(token['Result']);
            return next.handle(this.addTokenHeader(request, token['Result']));
          })
        );
      }
      else {
        this.sharedService.showHideLoader();
        this.isRefreshing = false;        
        this.authService.logout_noauth();
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: any) {
    /* for Spring Boot back-end */
     //return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });\
    return request.clone({ 
      headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token['AuthToken']).set('Accept-Language',this.sharedService.getSelectedLanguage()).set(TOKEN_REFRESH_KEY,token['refreshToken']) 
    });
    // return request.clone({ 
    //   headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)
    // });
    /* for Node.js Express back-end */
    //return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
  }
}

// export const authInterceptorProviders = [
//   { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
// ];